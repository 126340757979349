import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/project/blog/src/components/BlogPost/BlogPost.js";
import { Spring } from 'react-spring/renderprops';
import { State } from 'react-powerplug';
import styled, { keyframes } from 'styled-components';
import cssEngineSrc from '@assets/videos/css-engine.mp4';
import partTwoPreview from '@assets/videos/folding-pt2-pre.mp4';
import foldDemoImageSmallSrc from '@assets/images/francois-hoang-china-small.jpg';
import catsSrc from '@assets/images/cats.jpg';
import foldIssueSrc from '@assets/images/fold-issue.gif';
import Demo from '@components/Demo';
import FoldingDemo from '@components/FoldingDemo';
import MultipleChoiceControl, { Choice } from '@components/MultipleChoiceControl';
import SliderControl from '@components/SliderControl';
import TextLink from '@components/TextLink';
import SingleAxisDemo from '@components/SingleAxisDemo';
import VideoGif from '@components/VideoGif';
import InlineCode from '@components/InlineCode';
import Image from '@components/Image';
import AutoRotate from '@components/AutoRotate';
import LiveEditableCode from '@components/LiveEditableCode';
import RenderWhenOnscreen from '@components/RenderWhenOnscreen';
import MobileWarning from '@components/MobileWarning';
import NewsletterSignup from '@components/NewsletterSignup';
import mvpExampleCode from './code/mvp.example';
import mvpA11yExampleCode from './code/mvp-a11y.example';
import mvpWithBacksideExampleCode from './code/mvp-with-backside.example';
import backfaceInitialCode from './code/backface-initial.example';
import backfaceHiddenCode from './code/backface-hidden.example';
import backfaceFixedCode from './code/backface-fixed.example';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <MobileWarning mdxType="MobileWarning" />
    <p>{`In my day-to-day life as a front-end developer, I generally treat CSS as a collection of 2D layers. Other than reordering them using z-index, I don't often `}<em parentName="p">{`move`}</em>{` things in 3D space.`}</p>
    <p>{`And yet, for years now, browsers have bundled in a surprisingly capable 3D CSS engine! Someone even built an experimental first-person shooter prototype using it 😮`}</p>
    <VideoGif src={cssEngineSrc} caption={<>
      Believe it or not, this is just a collection of textured divs!{' '}
      <TextLink href="https://keithclark.co.uk/labs/css-fps/" mdxType="TextLink">
        View Live
      </TextLink>
    </>} mdxType="VideoGif" />
    <p>{`Today I'd like to leverage that 3D engine to perform a neat trick: folding up an image.`}</p>
    <p>{`Here's what we'll be building. Check out how this `}<a parentName="p" {...{
        "href": "https://unsplash.com/photos/2x6VHSRVqSA"
      }}>{`beautiful neon photo`}</a>{` by Francois Hoang is revealed through a 3D fold animation:`}</p>
    <FoldingDemo id="fold-intro" mdxType="FoldingDemo" />
    <p>{`This effect could be useful in a number of scenarios:`}</p>
    <ul>
      <li parentName="ul">{`As a preloader for images. They unfold once they're ready, and the folded copy could use a much-lower-res base64-encoded version!`}</li>
      <li parentName="ul">{`As an on-mount animation when clicking to view an image, to add whimsical charm to an otherwise common feature.`}</li>
      <li parentName="ul">{`For JS game development`}</li>
    </ul>
    <p>{`This tutorial is React-specific, but the concepts can easily be ported to vanilla JS/CSS, as well as other front-end frameworks.`}</p>
    <p><strong parentName="p">{`This is Part I of a two-part series.`}</strong>{` In the next part, we'll look at how to generalize this effect to work on `}<em parentName="p">{`any DOM node`}</em>{`, not just images.`}</p>
    <h1>{`The Trick`}</h1>
    <p>{`Unfortunately, the DOM has no primitive for this; you can't actually fold a DOM node in two.`}</p>
    <p>{`Instead, we need to be sneaky; we'll use `}<em parentName="p">{`two`}</em>{` images, and set them up so that it appears like a single image.`}</p>
    <p>{`Each image is set to take up 50% of the real height, and then the bottom image has its `}<inlineCode parentName="p">{`background-position`}</inlineCode>{` shifted up`}</p>
    <Demo id="transform-rotate" initialValues={{
      offset: 0
    }} controls={(values, updateValue) => <>
      <SliderControl id="offset" label="Offset" min={0} max={100} value={values.offset} updateValue={updateValue} mdxType="SliderControl" />
    </>} mdxType="Demo">
  {({
        offset
      }) => {
        const windowWidth = typeof window === 'undefined' ? 1024 : window.innerWidth;
        //
        const width = Math.min(333, windowWidth * 0.75);
        const height = width * 1.5;
        //
        return <Spring to={{
          offset
        }} config={{
          tension: 60,
          friction: 13
        }} mdxType="Spring">
        {interpolated => {
            return <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                width,
                height: height / 2,
                backgroundSize: `${width}px ${height}px`,
                backgroundImage: `url(${foldDemoImageSmallSrc})`,
                borderRadius: offset < 100 ? '0 0 0 0' : '10px 10px 0 0'
              }} />
              <div style={{
                width,
                height: height / 2,
                backgroundSize: `${width}px ${height}px`,
                backgroundImage: `url(${foldDemoImageSmallSrc})`,
                backgroundPosition: `0 ${interpolated.offset}%`,
                borderRadius: offset < 100 ? '0 0 0 0' : '0 0 10px 10px',
                transform: `translateY(${offset < 100 ? 5 : 0}px)`,
                transition: 'transform 250ms'
              }} />
              <InlineCode style={{
                position: 'relative',
                zIndex: 2,
                fontSize: windowWidth > 450 ? 24 : 16,
                marginTop: 30
              }} mdxType="InlineCode">
                background-position: '0 {Math.round(interpolated.offset)}%'
              </InlineCode>
            </div>;
          }}
      </Spring>;
      }}
    </Demo>
    <p>{`Pretty convincing, right? By juxtaposing the same image twice, and tweaking the offset of the background image, we're able to give the impression of a single image.`}</p>
    <p>{`To fold the bottom image up, we'll need to make use of a few CSS properties. This post will explain this technique in depth, but those who just want to see the code can find it `}<TextLink href="https://github.com/joshwcomeau/blog/blob/master/src/components/FoldableImage/FoldableImage.js" mdxType="TextLink">{`on Github`}</TextLink>{`.`}</p>
    <h2>{`Transform`}</h2>
    <p>{`Transform is our gateway to all sorts of effects. With transform, we can move stuff around, scale it larger and smaller, skew it, or rotate it.`}</p>
    <p>{`In our case, we want to use a rotation, along the X axis:`}</p>
    <Demo id="transform-rotate" initialValues={{
      axis: 'X',
      degrees: 0
    }} controls={(values, updateValue) => <>
      <MultipleChoiceControl id="axis" label="Axis" value={values.axis} updateValue={updateValue} mdxType="MultipleChoiceControl">
        <Choice id="X" mdxType="Choice">X Axis</Choice>
        <Choice id="Y" mdxType="Choice">Y Axis</Choice>
        <Choice id="Z" mdxType="Choice">Z Axis</Choice>
      </MultipleChoiceControl>
      <SliderControl id="degrees" label="Degrees" min={0} max={360} value={values.degrees} updateValue={updateValue} mdxType="SliderControl" />
    </>} mdxType="Demo">
  {({
        axis,
        degrees
      }) => {
        return <Spring to={{
          degrees
        }} config={{
          tension: 60,
          friction: 13
        }} mdxType="Spring">
        {interpolated => {
            const windowWidth = typeof window === 'undefined' ? 1024 : window.innerWidth;
            //
            const transform = `rotate${axis}(${interpolated.degrees}deg)`;
            //
            return <div>
              <div style={{
                paddingBottom: 30,
                textAlign: 'center'
              }}>
                <InlineCode style={{
                  position: 'relative',
                  zIndex: 2,
                  fontSize: windowWidth > 450 ? 24 : 16
                }} mdxType="InlineCode">
                  transform: rotate{axis}({Math.round(interpolated.degrees)}deg)
                </InlineCode>
              </div>
              <img width="100%" src={catsSrc} alt="cats in a basket" style={{
                transform,
                maxWidth: 400
              }} />
              <div style={{
                position: 'relative',
                zIndex: 2,
                padding: 30,
                textAlign: 'center'
              }}>
                Photo by{' '}
                <TextLink href="https://unsplash.com/photos/YCPkW_r_6uA" mdxType="TextLink">
                  Jari Hytönen
                </TextLink>
                .
              </div>
            </div>;
          }}
      </Spring>;
      }}
    </Demo>
    <h2>{`Perspective`}</h2>
    <p>{`By default, transforms still look very "2d". The rotations above don't look quite right, since objects closer to the viewer should look larger.`}</p>
    <p>{`The solution to this is to apply a "perspective" property to the parent container. The value is given in px, and represents the distance that the viewer is from the item being transformed. The smaller the number, the more intense the transform effect.`}</p>
    <RenderWhenOnscreen height={536} mdxType="RenderWhenOnscreen">
  <Demo id="perspective" initialValues={{
        perspective: 500
      }} controls={(values, updateValue) => <>
        <SliderControl id="perspective" label="Perspective" min={50} max={1250} value={values.perspective} updateValue={updateValue} mdxType="SliderControl" />
      </>} mdxType="Demo">
    {({
          perspective
        }) => {
          const windowWidth = typeof window === 'undefined' ? 1024 : window.innerWidth;
          //
          return <Spring to={{
            perspective
          }} config={{
            tension: 60,
            friction: 13
          }} mdxType="Spring">
          {interpolated => <div style={{
              perspective: interpolated.perspective + 'px'
            }}>
              <div style={{
                paddingBottom: 30,
                textAlign: 'center'
              }}>
                <InlineCode style={{
                  position: 'relative',
                  zIndex: 2,
                  fontSize: windowWidth > 450 ? 24 : 16
                }} mdxType="InlineCode">
                  perspective: {Math.round(interpolated.perspective)}px
                </InlineCode>
              </div>
              <AutoRotate mdxType="AutoRotate">
                <img width="100%" src={catsSrc} alt="cats in a basket" style={{
                  maxWidth: 400
                }} />
              </AutoRotate>
            </div>}
        </Spring>;
        }}
  </Demo>
    </RenderWhenOnscreen>
    <h2>{`Transform Origin`}</h2>
    <p>{`By default, rotations assume that you want to spin the items around their center point. The `}<inlineCode parentName="p">{`transform-origin`}</inlineCode>{` property allows us to change the pivot point for rotation (and for all other transforms as well!)`}</p>
    <p>{`Try changing it from the default "center" value to "top" or "bottom".`}</p>
    <RenderWhenOnscreen height={536} mdxType="RenderWhenOnscreen">
  <Demo id="transform-origin" initialValues={{
        originX: 'center',
        originY: 'center'
      }} controls={(values, updateValue) => <MultipleChoiceControl id="originY" label="Y" value={values.originY} updateValue={updateValue} mdxType="MultipleChoiceControl">
        <Choice id="top" mdxType="Choice">Top</Choice>
        <Choice id="center" mdxType="Choice">Center</Choice>
        <Choice id="bottom" mdxType="Choice">Bottom</Choice>
      </MultipleChoiceControl>} mdxType="Demo">
    {({
          originY
        }) => {
          const windowWidth = typeof window === 'undefined' ? 1024 : window.innerWidth;
          //
          return <div style={{
            perspective: '1200px'
          }}>
          <div style={{
              paddingBottom: 30
            }}>
            <InlineCode style={{
                position: 'relative',
                zIndex: 2,
                fontSize: windowWidth > 450 ? 24 : 16
              }} mdxType="InlineCode">
              transform-origin: {originY} center;
            </InlineCode>
          </div>
          <AutoRotate style={{
              transformOrigin: `${originY} center`
            }} mdxType="AutoRotate">
            <img width="100%" src={catsSrc} alt="cats in a basket" style={{
                maxWidth: 400
              }} />
          </AutoRotate>
        </div>;
        }}
  </Demo>
    </RenderWhenOnscreen>
    <h1>{`Our MVP`}</h1>
    <p>{`With all those pieces, we can achieve a "minimum viable product" for this effect. Here's what we get when we combine them:`}</p>
    <LiveEditableCode id="mvp-example" code={mvpExampleCode} split={[70, 30]} maxHeight={585} scope={{
      src: foldDemoImageSmallSrc
    }} mdxType="LiveEditableCode" />
    <p>{`With a little bit of CSS and a sprinkle of React state, we have the fundamental effect we're after!`}</p>
    <h1>{`Accessibility`}</h1>
    <p>{`There's a subtle problem to this solution: images are meant to have `}<inlineCode parentName="p">{`alt`}</inlineCode>{` tags, for users using screen readers. There is no way to specify an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` tag for a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` with a background image. By using a `}<inlineCode parentName="p">{`background-image`}</inlineCode>{`, we make this image invisible to assistive technologies.`}</p>
    <p>{`Happily, there's an easy solution. Let's use a real `}<inlineCode parentName="p">{`<img>`}</inlineCode>{` tag for the `}<em parentName="p">{`top half`}</em>{` of our folding element. In order to prevent the whole image from showing, we'll put it in a half-height div with `}<inlineCode parentName="p">{`overflow: hidden`}</inlineCode>{`.`}</p>
    <p>{`Here's what this looks like:`}</p>
    <LiveEditableCode id="mvp-a11y-example" code={mvpA11yExampleCode} split={[70, 30]} maxHeight={585} scope={{
      src: foldDemoImageSmallSrc
    }} mdxType="LiveEditableCode" />
    <p>{`Adding whimsical details is great, but not when it comes at the expense of accessibility.`}</p>
    <h1>{`Polishing`}</h1>
    <p>{`You may have noticed, though, that it's missing some of the bells and whistles of the original demo. Let's flesh some of these out.`}</p>
    <h2>{`Adding a backface`}</h2>
    <p>{`In our original demo, the "back" of the card has a slightly-transparent white background. The idea is to make it seem like a slightly-seethrough piece of paper.`}</p>
    <p>{`Let's tackle this problem in isolation at first, and then we can add it in to our full demo.`}</p>
    <p>{`First, we need a new `}<inlineCode parentName="p">{`div`}</inlineCode>{`, with a nearly-opaque white background. We'll position this in the same place as our card:`}</p>
    <RenderWhenOnscreen height={585 + 72} mdxType="RenderWhenOnscreen">
  <LiveEditableCode id="backface-initial" code={backfaceInitialCode} split={[70, 30]} maxHeight={585} scope={{
        src: foldDemoImageSmallSrc,
        styled,
        keyframes
      }} mdxType="LiveEditableCode" />
    </RenderWhenOnscreen>
    <p>{`Next, we need to make sure that this div is only shown when the card is facing the viewer. Happily, CSS has an elegant way to handle this scenario, built right into the language!`}</p>
    <p>{`We need to learn about a couple more properties.`}</p>
    <h3>{`Backface Visibility and Transform Style`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`backface-visibility`}</inlineCode>{` property allows us to specify whether an item should be visible when it's rotated more than 90 degrees in either direction.`}</p>
    <p>{`In this case, we also need to add `}<inlineCode parentName="p">{`transform-style: preserve-3d`}</inlineCode>{` to the parent element (the one responsible for the animation). This property allows elements to be positioned in 3D space, and it allows `}<inlineCode parentName="p">{`backface-visibility`}</inlineCode>{` to work correctly in this context.`}</p>
    <RenderWhenOnscreen height={585 + 72} mdxType="RenderWhenOnscreen">
  <LiveEditableCode id="backface-hidden" code={backfaceHiddenCode} split={[70, 30]} maxHeight={585} scope={{
        src: foldDemoImageSmallSrc,
        styled,
        keyframes
      }} mdxType="LiveEditableCode" />
    </RenderWhenOnscreen>
    <p>{`Eagle-eyed readers—or, those who can read Chinese—might've noticed that this effect is backwards. Right now, we `}<em parentName="p">{`only`}</em>{` see our white "back" when the card is facing forwards!`}</p>
    <p>{`It makes sense, because both the card and the backside are facing the same way. We're only hiding the backside when the whole thing is rotated around.`}</p>
    <p>{`We can fix this by being a little sneaky, and giving our backside element a 180-degree Y-axis rotation. Think of it like stacking two playing cards, and then flipping the top one over, so that the two cards are facing each other. This way, you always see the front of one element, and the back of the other.`}</p>
    <p>{`We can also apply a very slight Z-translate, to push the element a bit further from the viewer than the card. This addresses an issue where the elements can appear to flicker, because both the card and the backdrop are occupying the same point in 3D space. We push it away from the user so that the backside is actually behind the card itself (which means it'll be in front of the card when it's rotated).`}</p>
    <RenderWhenOnscreen height={585 + 72} mdxType="RenderWhenOnscreen">
  <LiveEditableCode id="backface-fixed" code={backfaceFixedCode} split={[70, 30]} maxHeight={585} scope={{
        src: foldDemoImageSmallSrc,
        styled,
        keyframes
      }} mdxType="LiveEditableCode" />
    </RenderWhenOnscreen>
    <p>{`Spacial orientation is a hard thing to visualize (especially with nested rotations!), so don't be discouraged if it's not immediately obvious why this trick works. Playing with the live-editable code should help!`}</p>
    <h2>{`Adding it into our original demo`}</h2>
    <p>{`How do we fit this into our `}<inlineCode parentName="p">{`<Foldable>`}</inlineCode>{` component? We can just add this new backside element to our "bottom half" div, and make sure to use 3D positioning:`}</p>
    <LiveEditableCode id="mvp-with-backside" code={mvpWithBacksideExampleCode} split={[70, 30]} maxHeight={585} scope={{
      src: foldDemoImageSmallSrc,
      styled,
      keyframes
    }} mdxType="LiveEditableCode" />
    <h2>{`Final Details`}</h2>
    <p>{`Here our original demo is again:`}</p>
    <FoldingDemo id="fold-conclusion" mdxType="FoldingDemo" />
    <p>{`There are a couple other small details we haven't covered.`}</p>
    <h3>{`Shading`}</h3>
    <p>{`As the card moves through its first 90 degrees, the bottom half darkens, as if there's a light source that can't light the surface as well as it angles up.`}</p>
    <p>{`For this effect, I added a new `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, with a variable `}<inlineCode parentName="p">{`opacity`}</inlineCode>{`. As the card rotation increases, I move closer to opaque.`}</p>
    <p>{`See `}<TextLink href="https://github.com/joshwcomeau/blog/blob/master/src/components/FoldableImage/FoldableImage.js#L23" mdxType="TextLink">{`this line`}</TextLink>{` in the source.`}</p>
    <h3>{`Thickness`}</h3>
    <p>{`As the card moves through the second half, there's the illusion of thickness, as if the card has an edge.`}</p>
    <p>{`I discovered this one by accident, by playing with the amount of Z-axis translation when adding the backside. To get backface-visibility working, it technically only needs to be `}<inlineCode parentName="p">{`0.01px`}</inlineCode>{`, but by setting it to `}<inlineCode parentName="p">{`2px`}</inlineCode>{`, it gives this nice illusion of depth.`}</p>
    <p>{`See `}<TextLink href="https://github.com/joshwcomeau/blog/blob/master/src/components/FoldableImage/FoldableImage.js#L83" mdxType="TextLink">{`this line`}</TextLink>{` in the source.`}</p>
    <h3>{`Translation and Bug-fixing`}</h3>
    <p>{`In this demo, I wanted the whole card to move up as it's unfolded, so that it always appeared centered in the parent container.`}</p>
    <p>{`This was accomplished with a `}<inlineCode parentName="p">{`transform: translateY()`}</inlineCode>{` on the parent, using the percentage of opening as the value to tween based on.`}</p>
    <p>{`I've also noticed that sometimes there can be a subtle flickering bug, in the crook of the fold, in certain browsers. The solution was to add a third copy of the image to fill in that small problematic area.`}</p>
    <p>{`Full details available `}<TextLink href="https://github.com/joshwcomeau/blog/blob/master/src/components/FoldableImage/FoldableImage.js" mdxType="TextLink">{`in the source`}</TextLink>{`.`}</p>
    <h3>{`Spring physics`}</h3>
    <p>{`In the demo, I'm using React Spring to animate changes in value, when the slider is dragged. Spring physics produce much more organic, beautiful motion than using traditional easing. Its use is outside the scope of this article, but it has `}<TextLink href="https://react-spring.io/docs" mdxType="TextLink">{`excellent documentation`}</TextLink>{` you can consult!`}</p>
    <h1>{`Conclusion`}</h1>
    <p>{`Effects like this can be quite a lot of trouble, but the beauty of React is that it encourages the creation of reusable effects. After following this tutorial, you'll wind up with a `}<inlineCode parentName="p">{`<Foldable>`}</inlineCode>{` component you can easily drop into any future project!`}</p>
    <p>{`Because this effect is non-trivial, it's also quite rare. This means that it has more of a punch, because it's not something that users are accustomed to!`}</p>
    <p>{`In this post, we looked exclusively at images, but you may wish to use this effect on other kinds of elements. For example, in a demo I created for a `}<TextLink href="https://github.com/joshwcomeau/react-europe-talk-2018" mdxType="TextLink">{`React Europe talk`}</TextLink>{`, I show how a form can be folded up like a letter:`}</p>
    <VideoGif src={partTwoPreview} caption={<>A sample of folding a modal up.</>} mdxType="VideoGif" />
    <p>{`Happily, we can apply many of the tricks we learned here to produce the same effect, but there a couple additional issues we'll have to contend with. I plan to write a second part addressing this concern in the coming weeks!`}</p>
    <h1>{`Join the Newsletter`}</h1>
    <p>{`If you're interested in content like this, you should join my newsletter! Subscribers get sneak peeks at upcoming posts.`}</p>
    <NewsletterSignup id="fold-conclusion" mdxType="NewsletterSignup" />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      